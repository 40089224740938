<template>
    <div v-if="visible" class="help_info" @click="hideHelp()">
        <div class="help_info__wrapper">
            <div class="help_info_text">
                <i class="fi fi-rr-cursor-finger"></i>
                <p>Для вызова меню, зажмите карточку</p>
            </div>
            <div class="close">
                Закрыть
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            visible: false
        }
    },
    watch: {
        '$route.query'(val) {
            const check = localStorage.getItem('hideHelpLongpress')
            if(!check && !this.visible && !Object.keys(this.$route.query)?.length) {
                this.visible = true
            }
        }
    },
    created() {
        this.$nextTick(() => {
            const check = localStorage.getItem('hideHelpLongpress')
            if(!check && !Object.keys(this.$route.query)?.length) {
                this.visible = true
            }
        })
    },
    methods: {
        hideHelp() {
            localStorage.setItem('hideHelpLongpress', true)
            this.visible = false
        }
    }
}
</script>

<style lang="scss" scoped>
.help_info{
    position: fixed;
    z-index: 500;
    top: 50px;
    left: 0;
    bottom: 50px;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    color: #ffffff;
    .fi{
        font-size: 64px;
    }
    p{
        margin-bottom: 0px;
        margin-top: 20px;
        font-size: 18px;
    }
    .help_info_text{
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
    }
    &__wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: relative;
    }
    .close{
        position: absolute;
        bottom: 20px;
        text-align: center;
        left: 0;
        width: 100%;
        font-weight: 300;
    }
}
</style>